table.table {
    
    td.icon, th.icon {
        text-align: center;
        width: 40px;
    }

    td.handler, th.handler {
        text-align: center;
        width: 40px;
    }

    td.id, th.id {
        text-align: right;
        width: 70px;
    }
}
