.pre {
  white-space: pre-line;
}

table.table {
  td.fit,
  th.fit {
    white-space: nowrap;
    width: 1%;
  }

  td.icon,
  th.icon {
    text-align: center;
    width: 40px;
  }

  td.handler,
  th.handler {
    text-align: center;
    width: 40px;
  }

  &.ll-nohead > tbody > tr:first-child td {
    border-top: none;
  }
}

.comma-list {
  > * {
    margin-left: 0.50em;

    &:first-child {
      margin-left: 0;
    }

    &::after {
      content: ",";
    }

    &:last-child::after {
      content: "";
    }
  }
}

.ll-fieldgroup {
  // margin-bottom: 0.4rem;

  &::last-child {
    margin-bottom: 0;
  }
}

.ll-label {
  font-weight: bold;
  margin-right: 0.4rem;

  @at-root span#{&}{
    display: inline-block;
  }

  &::after {
    content: ':';
  }

  &.icon::after {
    content: '';
  }
}

.ll-value {

  @at-root span#{&}{
    display: inline-block;
  }

  &:empty:after {
    content: '--';
  }

}
