// Config: mixins and all variables
@import 'config';

// Bootstrap
@import '~bootstrap/scss/bootstrap'; 

// Toastr 
@import '~ngx-toastr/toastr.css';
//@import '~ngx-toastr/toastr-bs4-alert';

// Common: general elements
@import 'common/typography';
@import 'common/form-validation';
@import 'common/shared-classes';
@import 'common/colors';

// Blocks:
@import 'blocks/sortables';
@import 'blocks/tables';
@import 'blocks/tabs';
