/*
--breakpoint-xs: 0;
--breakpoint-sm: 576px;
--breakpoint-md: 768px;
--breakpoint-lg: 992px;
--breakpoint-xl: 1200px;
*/

@mixin breakpoint($point) {
    @if $point == xs {
        @media (max-width: 575px) {@content;}
    }
    @else if $point == sm {
        @media (min-width: 576px) and (max-width: 767px) {@content;}
    }
    @else if $point == md {
        @media (min-width: 768px) and (max-width: 991px) {@content;}
    }
    @else if $point == lg {
        @media (min-width: 992px) and (max-width: 1199px) {@content;}
    }
    @else if $point == xl {
        @media (min-width: 1200px) {@content;}
    }
}
