form {

    label.required:after {
        content: "*";
        color: $red;
        margin-left: 3px;
    }

    .ng-touched.ng-invalid:not(.ng-pristine) {
        border: 1px solid $red;
    }
}
