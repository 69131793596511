// Colors
$white: #FFFFFF;
//--
$yellow: #FFC600;
//--
$orange: #F5A623;
//--
$fuchsia: #EB4B5E;
//--
$red: #B9233F;
//--
$green: #005562;
$green-dark: #003139;
$green-light: #005F6E;
//--
$green-alt: #3CB16C;
//--
$gray: #9B9B9B;
$gray-light: #f3f4f8;
$gray-light-extra: #FAFAFA;
//--
$gray-alt: #C0C0BD;
//--
$black: #000000;

// Animations
$transition-1: all 0.2s ease 0s;
$transition-2: all 1s ease 0s;
$transition-3: all 0.5s ease 0s;

// Alpha colors
$alpha-1: rgba(0,0,0,0.3);
$alpha-2: rgba(0,0,0,0.5);

// Shadows
$shadow-1: 0px 0px 5px rgba(0,0,0,0.6);
$shadow-2: 0px 0px 15px rgba(0,0,0,0.15);

// Mobile
$mobile-menu-speed: 0.7s;
$mobile-menu-xs-width: 270px;
$mobile-menu-sm-height: 300px;
