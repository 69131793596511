div.tabs {
    margin-top: 1.5em;

    > nav {

    }

    > div.tab-content {

        > .tab-pane {
            
            > .card {
                border-top: none
            }
        }
    }
}